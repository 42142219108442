import { CustomerAddressJson } from "@/types/Customer.type";
import { BaseModelJson } from "../types/BaseModelJson.type";
import BaseModel from "./BaseModel";
import { SelectOptionFormSelect } from "../types/Component.type";

class CustomerAddressModel
	extends BaseModel
	implements BaseModelJson<CustomerAddressJson>
{
	full_name: string;
	address: string;
	phone: string;
	district: number;
	province: number;
	country: number;
	ward: number;
	road: string;
	type: number;
	id: number;
	customer_id: number;
	is_default: number;
	type_name: string;
	status: number;
	is_deleted: number;
	date_deleted: number;
	date_created: number;
	date_modified: number;
	full_address: string;

	first_name: string;
	last_name: string;
	constructor(json: CustomerAddressJson) {
		super();
		this.full_name = json.full_name || "";
		this.address = json.address || "";
		this.phone = json.phone || "";
		this.district = json.district || 0;
		this.province = json.province || 0;
		this.country = json.country || 0;
		this.ward = json.ward || 0;
		this.road = json.road || "";
		this.type = json.type || 0;
		this.id = json.id || 0;
		this.customer_id = json.customer_id || 0;
		this.is_default = json.is_default || 0;
		this.type_name = json.type_name || "";
		this.status = json.status || 0;
		this.is_deleted = json.is_deleted || 0;
		this.date_deleted = json.date_deleted || 0;
		this.date_created = json.date_created || 0;
		this.date_modified = json.date_modified || 0;
		this.full_address = json.full_address || "";
		this.first_name = json.first_name || "";
		this.last_name = json.last_name || "";
	}

	static getDefaultData(): CustomerAddressJson {
		return {
			full_name: "",
			address: "",
			phone: "",
			district: 0,
			province: 0,
			country: 0,
			ward: 0,
			road: "",
			type: 0,
			id: 0,
			customer_id: 0,
			is_default: 0,
			type_name: "",
			status: 0,
			is_deleted: 0,
			date_deleted: 0,
			date_created: 0,
			date_modified: 0,
			full_address: "",

			first_name: "",
			last_name: "",
		};
	}

	toJson(): CustomerAddressJson {
		return {
			full_name: this.full_name,
			address: this.address,
			phone: this.phone,
			district: this.district,
			province: this.province,
			country: this.country,
			ward: this.ward,
			road: this.road,
			type: this.type,
			id: this.id,
			customer_id: this.customer_id,
			is_default: this.is_default,
			type_name: this.type_name,
			status: this.status,
			is_deleted: this.is_deleted,
			date_deleted: this.date_deleted,
			date_created: this.date_created,
			date_modified: this.date_modified,
			full_address: this.full_address,
			first_name: this.first_name,
			last_name: this.first_name,
		};
	}

	static getAddressTypeList(): SelectOptionFormSelect[] {
		return [
			{
				value: 1,
				label: "Nhà",
			},
			{
				value: 2,
				label: "Công ty",
			},
			{
				value: 3,
				label: "Khác",
			},
		];
	}
	static getAddressType(value: number): SelectOptionFormSelect | undefined {
		return this.getAddressTypeList().find((item) => item.value === value);
	}
}

export default CustomerAddressModel;
