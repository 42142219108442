import { FileJson } from "../types/File.type";
import BaseModel from "./BaseModel";
import { BaseModelJson } from "../types/BaseModelJson.type";
import Helper from "@/utils/helper";
class FileModel extends BaseModel implements BaseModelJson<FileJson> {
	static OBJECTTYPE_FILE(): number {
		return 10;
	}

	static OBJECTTYPE_FILE_PERSONAL(): number {
		return 20;
	}

	static OBJECTTYPE_SIMPLE(): number {
		return 30;
	}

	static OBJECTTYPE_CMS(): number {
		return 40;
	}

	company_id: number;
	creator_id: number;
	id: number;
	directory_id: number;
	title: string;
	description: string;
	md5_hash: string;
	file_path: string;
	object_type: number;
	object_id: number;
	width: number;
	height: number;
	randomcode: string;
	extension: string;
	size_in_byte: number;
	is_directory: number;
	date_created: number;
	date_modified: number;
	url: string;

	constructor(json: FileJson) {
		super();

		this.company_id = json.company_id || 0;
		this.creator_id = json.creator_id || 0;
		this.id = json.id || 0;
		this.directory_id = json.directory_id || 0;
		this.title = json.title || "";
		this.description = json.description || "";
		this.is_directory = json.is_directory || 0;
		this.md5_hash = json.md5_hash || "";
		this.file_path = json.file_path || "";
		this.width = json.width || 0;
		this.height = json.height || 0;
		this.object_type = json.object_type || 0;
		this.object_id = json.object_id || 0;
		this.randomcode = json.randomcode || "";
		this.extension = json.extension || "";
		this.size_in_byte = json.size_in_byte || 0;
		this.date_created = json.date_created || 0;
		this.date_modified = json.date_modified || 0;
		this.url = json.url || "";
	}

	static getDefaultData(): FileJson {
		return {
			company_id: 0,
			creator_id: 0,
			id: 0,
			directory_id: 0,
			title: "",
			description: "",
			is_directory: 0,
			md5_hash: "",
			file_path: "",
			width: 0,
			height: 0,
			object_type: 0,
			object_id: 0,
			randomcode: "",
			extension: "",
			size_in_byte: 0,
			date_created: 0,
			date_modified: 0,
			url: "",
		};
	}

	toJson(): FileJson {
		return {
			company_id: this.company_id,
			creator_id: this.creator_id,
			id: this.id,
			directory_id: this.directory_id,
			title: this.title,
			description: this.description,
			is_directory: this.is_directory,
			md5_hash: this.md5_hash,
			file_path: this.file_path,
			width: this.width,
			height: this.height,
			object_type: this.object_type,
			object_id: this.object_id,
			randomcode: this.randomcode,
			extension: this.extension,
			size_in_byte: this.size_in_byte,
			date_created: this.date_created,
			date_modified: this.date_modified,
			url: this.url,
		};
	}

	static getImageExtensions(): string[] {
		return ["jpg", "png", "jpeg"];
	}

	isImage(): boolean {
		return FileModel.getImageExtensions().includes(this.extension);
	}

	static getThumbnailFromUrl(
		url: string | string[],
		width?: number,
		height?: number,
		action?: "resize" | "crop"
	): string {
		let urlImage = "";

		if (typeof url === "string") {
			urlImage = url;
		} else if (url && url.length > 0) {
			urlImage = url[0];
		}

		if (urlImage && urlImage.length > 0) {
			//base url
			let myurl =
				this.getThumbnailServerPrefix(
					typeof action !== "undefined" && ["resize", "crop"].includes(action)
						? action
						: "resize"
				) + urlImage;

			//always append width, default to 200
			myurl +=
				"&width=" +
				(typeof width !== "undefined" && !isNaN(width) && width > 0
					? width
					: 200);

			//height is OPTIONAL
			if (typeof height !== "undefined" && !isNaN(height) && height > 0) {
				myurl += "&height=" + height;
			}

			return myurl;
		}

		return "";
	}

	static getThumbnailFromFilePath(
		url: string,
		width?: number,
		height?: number,
		action?: "resize" | "crop"
	): string {
		const getThumbnailServerPrefix = (action?: "resize" | "crop"): string => {
			const photoAction = action || "resize";
			return `${process.env.REACT_APP_IMAGINARY_BASE_URL}/${photoAction}?url=${process.env.REACT_APP_ASSET_URL}/`;
		};

		if (url && url.length > 0) {
			let myUrl =
				getThumbnailServerPrefix(
					action && ["resize", "crop"].includes(action) ? action : "resize"
				) + url;

			myUrl += `&width=${width && !isNaN(width) && width > 0 ? width : 200}`;

			if (height && !isNaN(height) && height > 0) {
				myUrl += `&height=${height}`;
			}

			return myUrl;
		} else {
			return "";
		}
	}

	getThumbnail(
		width?: number,
		height?: number,
		action?: "resize" | "crop"
	): string {
		//only return IMAGE for imagetype
		if (this.isImage()) {
			return FileModel.getThumbnailFromUrl(
				this.file_path,
				width,
				height,
				action
			);
		} else {
			//for non-image, just return icon
			return this.getIcon();
		}
	}

	getThumbnailSquare(size: number): string {
		return this.getThumbnail(size, size, "crop");
	}

	getIcon(): string {
		var iconUrl = "";

		if (this.is_directory === 1) {
			iconUrl = "/imgs/filetype/folder_special.png";
		} else {
			var ext = this.extension;

			if (ext === "") {
				iconUrl = "folder.png";
			} else if (
				["jpg", "jpeg", "png", "gif", "ai", "psd", "tiff"].includes(ext)
			) {
				iconUrl = "jpg.png";
			} else if (ext === "pdf") {
				iconUrl = "pdf.png";
			} else if (ext === "html") {
				iconUrl = "html.png";
			} else if (ext === "xls" || ext === "xlsx") {
				iconUrl = "xls.png";
			} else if (["mp3", "ogg", "wav", "wma"].includes(ext)) {
				iconUrl = "mp3.png";
			} else if (["mp4", "mov", "avi"].includes(ext)) {
				iconUrl = "mov.png";
			} else if (["ppt", "pptx"].includes(ext)) {
				iconUrl = "ppt.png";
			} else if (["doc", "docx"].includes(ext)) {
				iconUrl = "doc.png";
			} else if (["zip", "gz", "bzip", "gzip", "7z"].includes(ext)) {
				iconUrl = "zip.png";
			} else if (["rar"].includes(ext)) {
				iconUrl = "rar.png";
			} else {
				iconUrl = "html.png";
			}

			iconUrl = "/imgs/filetype/" + iconUrl;
		}

		return iconUrl;
	}

	/**
  Convert file to UploadFile list for Upload/Form components of antdesign
   */
	static convertToUploadFiles(files: FileModel[]): any[] {
		return files
			.filter((f) => f.id > 0)
			.map((f) => ({
				uid: f.md5_hash,
				name: f.title,
				status: "done",
				thumbUrl: f.url,
				url: f.url,
				response: f.toJson(),
			}));
	}

	static getFirstFileId(files: any[]): number {
		return Array.isArray(files) &&
			files.length > 0 &&
			typeof files[0].response !== "undefined" &&
			typeof files[0].response !== "string" &&
			typeof files[0].response.id !== "undefined" &&
			files[0].response.id > 0
			? files[0].response.id
			: 0;
	}

	// in somecase, we just have FileID and URL
	// but we need to create validated ModelProps json data (for init)
	static parseDataSimple({
		company_id,
		creator_id,
		id,
		url,
		object_type,
	}: {
		company_id: number;
		creator_id: number;
		id: number;
		url: string;
		object_type: number;
	}): FileJson {
		const urlParts = url.split("/").filter((p) => p.length > 0);

		return {
			company_id,
			creator_id,
			id,
			directory_id: 0,
			title: urlParts[urlParts.length - 1],
			description: "",
			is_directory: 0,
			md5_hash: "",
			file_path:
				urlParts.length >= 4
					? urlParts.slice(-4).join("/")
					: urlParts.join("/"),
			width: 200,
			height: 200,
			object_type,
			object_id: 0,
			randomcode: "",
			extension: Helper.fileExtension(url),
			size_in_byte: 1000,
			date_created: 0,
			date_modified: 0,
			url,
		};
	}

	static getThumbnailServerPrefix(
		action?: "resize" | "crop",
		type?: "kv" | "upload"
	): string {
		const photoAction = action || "resize";
		const typeGet = type || "kv";
		const link =
			typeGet === "kv"
				? "/" + photoAction + "?url="
				: "/" + photoAction + "?file=";
		return process.env.REACT_APP_IMAGINARY_BASE_URL + link;
	}
}

export default FileModel;
