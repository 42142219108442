import { CustomerGenders, CustomerJson, Rank } from "@/types/Customer.type";
import { BaseModelJson } from "../types/BaseModelJson.type";
import { FileJson } from "../types/File.type";
import BaseModel from "./BaseModel";
import FileModel from "./FileModel";

class CustomerModel extends BaseModel implements BaseModelJson<CustomerJson> {
  company_id: number;
  user_id: number;
  id: number;
  user_name: string;
  creator_id: number;
  customer_type_id: number;
  code: string;
  full_name: string;
  first_name: string;
  last_name: string;
  gender: CustomerGenders;
  rank: Rank;
  rank_type: number;
  birthday: number;
  email: string;
  phone: string;
  email_verified: number;
  phone_verified: number;
  country: number;
  province: number;
  district: number;
  ward: number;
  road: string;
  address: string;
  region_id: number;
  sub_region_id: number;
  sub_sub_region_id: number;
  tag: string;
  note: string;
  invoicing_detail: string;
  count_order: number;
  point: number;
  money: number;
  tax_buyer_name: string;
  tax_buyer_address: string;
  tax_buyer_phone: string;
  tax_buyer_account_number: string;
  tax_buyer_tax_number: string;
  ignore_auto_upgrade: number;
  order_spent: number;
  order_count: number;
  order_lastdate: number;
  order_lastid: number;
  order_lastnumber: number;
  order_lastspent: number;
  date_created: number;
  datemodified_username: number;
  datemodified_contact: number;
  datemodified_email: number;
  datemodified_phone: number;
  datemodified_edit_user: number;
  date_modified: number;
  date_first_ordered: number;
  date_last_ordered: number;
  status: number;
  isdeleted: number;
  date_deleted: number;
  total_collection: number;
  avartar: FileJson;

  constructor(json: CustomerJson) {
    super();
    this.company_id = json.company_id || 0;
    this.user_id = json.user_id || 0;
    this.id = json.id || 0;
    this.user_name = json.user_name || "";
    this.creator_id = json.creator_id || 0;
    this.customer_type_id = json.customer_type_id || 0;
    this.code = json.code || "";
    this.full_name = json.full_name || "";
    this.first_name = json.first_name || "";
    this.last_name = json.last_name || "";
    this.gender = json.gender || 0;
    this.rank = json.rank || {
      customer_id: 0,
      datecreated: 0,
      day_rank: 0,
      id: 0,
      total_rank_accumulate: 0,
      total_spending: 0,
      rank_type_id: 0,
      status: 0,
      max_day_rank: 0,
      min_rank_type: 0,
      max_rank_type: 0,
      rank_next: 0,
    };
    this.rank_type = json.rank_type || 0;
    this.birthday = json.birthday || 0;
    this.email = json.email || "";
    this.phone = json.phone || "";
    this.email_verified = json.email_verified || 0;
    this.phone_verified = json.phone_verified || 0;
    this.country = json.country || 0;
    this.province = json.province || 0;
    this.district = json.district || 0;
    this.ward = json.ward || 0;
    this.road = json.road || "";
    this.address = json.address || "";
    this.region_id = json.region_id || 0;
    this.sub_region_id = json.sub_region_id || 0;
    this.sub_sub_region_id = json.sub_sub_region_id || 0;
    this.tag = json.tag || "";
    this.note = json.note || "";
    this.invoicing_detail = json.invoicing_detail || "";
    this.count_order = json.count_order || 0;
    this.point = json.point || 0;
    this.money = json.money || 0;
    this.tax_buyer_name = json.tax_buyer_name || "";
    this.tax_buyer_address = json.tax_buyer_address || "";
    this.tax_buyer_phone = json.tax_buyer_phone || "";
    this.tax_buyer_account_number = json.tax_buyer_account_number || "";
    this.tax_buyer_tax_number = json.tax_buyer_tax_number || "";
    this.ignore_auto_upgrade = json.ignore_auto_upgrade || 0;
    this.order_spent = json.order_spent || 0;
    this.order_count = json.order_count || 0;
    this.order_lastdate = json.order_lastdate || 0;
    this.order_lastid = json.order_lastid || 0;
    this.order_lastnumber = json.order_lastnumber || 0;
    this.order_lastspent = json.order_lastspent || 0;
    this.date_created = json.date_created || 0;
    this.datemodified_username = json.datemodified_username || 0;
    this.datemodified_contact = json.datemodified_contact || 0;
    this.datemodified_email = json.datemodified_email || 0;
    this.datemodified_phone = json.datemodified_phone || 0;
    this.datemodified_edit_user = json.datemodified_edit_user || 0;
    this.date_modified = json.date_modified || 0;
    this.date_first_ordered = json.date_first_ordered || 0;
    this.date_last_ordered = json.date_last_ordered || 0;
    this.status = json.status || 0;
    this.isdeleted = json.isdeleted || 0;
    this.date_deleted = json.date_deleted || 0;
    this.total_collection = json.total_collection || 0;
    this.avartar = new FileModel(json.avartar || FileModel.getDefaultData());
  }

  static getDefaultData(): CustomerJson {
    return {
      company_id: 0,
      user_id: 0,
      id: 0,
      user_name: "",
      creator_id: 0,
      customer_type_id: 0,
      code: "",
      full_name: "",
      first_name: "",
      last_name: "",
      gender: 0,
      rank: {
        customer_id: 0,
        datecreated: 0,
        day_rank: 0,
        id: 0,
        total_spending: 0,
        rank_type_id: 0,
        status: 0,
        total_rank_accumulate: 0,
        max_day_rank: 0,
        min_rank_type: 0,
        max_rank_type: 0,
        rank_next: 0,
      },
      rank_type: 0,
      birthday: 0,
      email: "",
      phone: "",
      email_verified: 0,
      phone_verified: 0,
      country: 0,
      province: 0,
      district: 0,
      ward: 0,
      road: "",
      address: "",
      region_id: 0,
      sub_region_id: 0,
      sub_sub_region_id: 0,
      tag: "",
      note: "",
      invoicing_detail: "",
      count_order: 0,
      point: 0,
      money: 0,
      tax_buyer_name: "",
      tax_buyer_address: "",
      tax_buyer_phone: "",
      tax_buyer_account_number: "",
      tax_buyer_tax_number: "",
      ignore_auto_upgrade: 0,
      order_spent: 0,
      order_count: 0,
      order_lastdate: 0,
      order_lastid: 0,
      order_lastnumber: 0,
      order_lastspent: 0,
      date_created: 0,
      datemodified_username: 0,
      datemodified_contact: 0,
      datemodified_email: 0,
      datemodified_phone: 0,
      datemodified_edit_user: 0,
      date_modified: 0,
      date_first_ordered: 0,
      date_last_ordered: 0,
      status: 0,
      isdeleted: 0,
      date_deleted: 0,
      total_collection: 0,
      avartar: FileModel.getDefaultData(),
    };
  }

  toJson(): CustomerJson {
    return {
      company_id: this.company_id,
      user_id: this.user_id,
      id: this.id,
      user_name: this.user_name,
      creator_id: this.creator_id,
      customer_type_id: this.customer_type_id,
      code: this.code,
      full_name: this.full_name,
      first_name: this.first_name,
      last_name: this.last_name,
      gender: this.gender,
      rank: this.rank,
      rank_type: this.rank_type,
      birthday: this.birthday,
      email: this.email,
      phone: this.phone,
      email_verified: this.email_verified,
      phone_verified: this.phone_verified,
      country: this.country,
      province: this.province,
      district: this.district,
      ward: this.ward,
      road: this.road,
      address: this.address,
      region_id: this.region_id,
      sub_region_id: this.sub_region_id,
      sub_sub_region_id: this.sub_sub_region_id,
      tag: this.tag,
      note: this.note,
      invoicing_detail: this.invoicing_detail,
      count_order: this.count_order,
      point: this.point,
      money: this.money,
      tax_buyer_name: this.tax_buyer_name,
      tax_buyer_address: this.tax_buyer_address,
      tax_buyer_phone: this.tax_buyer_phone,
      tax_buyer_account_number: this.tax_buyer_account_number,
      tax_buyer_tax_number: this.tax_buyer_tax_number,
      ignore_auto_upgrade: this.ignore_auto_upgrade,
      order_spent: this.order_spent,
      order_count: this.order_count,
      order_lastdate: this.order_lastdate,
      order_lastid: this.order_lastid,
      order_lastnumber: this.order_lastnumber,
      order_lastspent: this.order_lastspent,
      date_created: this.date_created,
      datemodified_username: this.datemodified_username,
      datemodified_contact: this.datemodified_contact,
      datemodified_email: this.datemodified_email,
      datemodified_phone: this.datemodified_phone,
      datemodified_edit_user: this.datemodified_edit_user,
      date_modified: this.date_modified,
      date_first_ordered: this.date_first_ordered,
      date_last_ordered: this.date_last_ordered,
      status: this.status,
      isdeleted: this.isdeleted,
      date_deleted: this.date_deleted,
      total_collection: this.total_collection,
      avartar: this.avartar,
    };
  }
}

export default CustomerModel;
